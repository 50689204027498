import { useEffect, useState } from 'react';

type IUseScroll = [number, number];

export function useScroll(w = window): IUseScroll {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [previousScrollPosition, setPreviousScrollPosition] = useState(0);

    function handleScroll() {
        setScrollPosition(prev => {
            setPreviousScrollPosition(prev);
            return w.scrollY
        });
    }

    useEffect(() => {
        w.addEventListener('scroll', handleScroll, { passive: true });

        return () => w.removeEventListener('scroll', handleScroll);
    }, []);

    return [scrollPosition, previousScrollPosition];
}
