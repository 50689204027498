import React from 'react';
import { useScroll } from '../utils/use-scroll';

export default function CrowdfundingModal() {
    const [scroll, previousScroll] = useScroll();

    const dscroll = scroll - previousScroll;

    const classes = [
        'bg-crowdbase-brand',
        'bottom-0',
        'desktop:p-8',
        'desktop:text-big',
        'fixed',
        'left-0',
        'p-4',
        'right-0',
        'text-offwhite z-10',
        'text-standard',
        'transition-all',
        'transition-duration-short',
        'z-modal',
    ];

    if (dscroll <= 0)
        classes.push('slid-below');

    return <aside className={classes.join(' ')}>
        <div className='flex items-center justify-space-between m-auto max-w-280'>
            <div className='text-white'>Become a part owner in Phi! (capital at risk)</div>
            <a href='https://crowdbase.eu/en/campaigns/Phi' target='_blank' className='bg-brand bold desktop:px-6 desktop:py-3 px-4 py-2 rounded text-dark-grey'>Learn More</a>
            <img className='desktop:max-w-64 max-w-32' src='/crowdbase-logo.webp'/>
        </div>
    </aside>
}

// https://crowdbase.eu/en/campaigns/Phi
